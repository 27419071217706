@import '../../Styles/global.scss';

.recent-work{
    &__title {
        font-family: $font-bold;
        font-size: 20px;
        color: #185d8a;
        text-align: center;
        letter-spacing: 0.88px;
        padding: 30px 0;
        margin: 0;

        @include mobile-tablet {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.32px;
        }
    }
}

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #185d8a;
    margin: 0 20px 50px 20px;
    border-radius: 7px;

    @include tablet-view {
        display: none;
    }

    @include tablet-desktop-1024 {
        display: none;
    }

    @include desktop-view {
        display: none;
    }

    &__title {
        font-family: $font-semibold;
        color: #f8fafd;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.88px;
        margin: 0;
        padding: 8px 0 7px 0; 

        @include mobile-tablet {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.32px;
            padding: 15px 0; 
        }
    }

    &__description {
        font-family: $font-light;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.49px;
        color: #f8fafd;
        padding: 18px 20px 12px 20px;
        text-align: center;

        @include mobile-tablet {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.79px;
        }
    }

    &__link {
        text-decoration: none;
        font-family: $font-light;
        font-size: 14px;
        letter-spacing: 0.62px;
        color: #185d8a;
        background-color: #aff2e3;
        border-radius: 50px;
        padding: 11px 22px;
        margin-bottom: 24px;

        @include mobile-tablet {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.79px;
        }
    }

    &__fetch {
        background-image: url(../../Asset/Image/Fetch-img/group_7_copy.png);
        background-position: center;
        background-size: cover;
        width: 187px;
        height: 307px;
    }

    &__match {
        background-image: url(../../Asset/Image/Match-Maker/group_8.png);
        background-position: center;
        background-size: cover;
        width: 240px;
        height: 140px;
    }

    &__qportfolio {
        // background-image: url(../../Asset/Image/QPortfolio/qcombined2.png);
        background-image: url(../../Asset/Image/JodiPortfolio/Group_68.svg);
        background-position: center;
        background-size: cover;
        width: 240px;
        height: 140px;
    }
}

.desktop {

    @include mobile {
        display: none;
    }
    
    @include mobile-tablet {
        display: none;
    }

    @include tablet-view {

        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #185d8a;
        margin: 0 20px 50px 20px;
        border-radius: 7px;
        height: 360px;

        &__container {
            width: 50%;
            padding: 0 35px 0 0;

            &__right {
                width: 50%;
                text-align: end;
            }

            &__title {
                font-family: $font-semibold;
                color: #f8fafd;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: 0.88px;
                margin: 0;

                @include mobile-tablet {
                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: 1.32px;
                    padding: 0 0 15px 0; 
                }
            }

            &__description {
                font-family: $font-light;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.49px;
                color: #f8fafd;
                margin: 0 0 30px 0;

                @include mobile-tablet {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.79px;
                }
            }

            &__link {
                text-decoration: none;
                font-family: $font-light;
                font-size: 14px;
                letter-spacing: 0.62px;
                color: #185d8a;
                background-color: #aff2e3;
                border-radius: 50px;
                padding: 11px 22px;
                margin-bottom: 24px;
                background-position: center;
                transition: background 0.8s;
            }

            &__link:hover {
                text-decoration: none;
                font-family: $font-light;
                font-size: 14px;
                letter-spacing: 0.62px;
                color: #185d8a;
                background-color: #aff2e3;
                border-radius: 50px;
                padding: 11px 22px;
                margin-bottom: 24px;
                background: #aff2e3 radial-gradient(circle, transparent 1%, #cdf7ef  1%) center/15000%;
            }

            &__link:active {
                background-color: #aff2e3;
                background-size: 100%;
                transition: background 0s;
            }
        }

        &__portfolio {
            width: 350px;
            padding-left: 25px;
        }

        &__fetch {
            width: 250px;
            height: 400px;
        }

        &__match {
            width: 240px;
            height: 140px;
            padding: 0 35px 0 0;
        }

        &__qportfolio {
            width: 400px;
        }

    }

    @include tablet-desktop-1024 {
        width: 940px;
        margin: 0 auto 50px auto;
    }
}