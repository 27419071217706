@import '../../Styles/global.scss';

p {
    margin: 0;
}

.main {
    
    &__hero {
        background-image: url(../../Asset/Image/Portfolio/group_5.png);
        background-color: #f8fafd;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;

        @include tablet-view {
            background-image: url(../../Asset/Image/Portfolio/group_10.svg);
            background-size: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &__header {
        font-family: $font-bold;
        font-size: 20px;
        text-align: center;
        color: #f8fafd;
        letter-spacing: 0.88px;
        line-height: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80% 38px 0 38px; 
        
        &__bottom {
            margin: 0 0 32px 0;
        }

        @include mobile-tablet {
            font-family: $font-bold;
            font-size: 30px;
            letter-spacing: 1.32px;
            line-height: 40px;
            padding: 70% 38px 0 38px; 
        }

        @include tablet-view {
            color: #185d8a;
            padding: 35% 0 0 20%;
            align-items: baseline;
        }

        @include desktop-view  {
            color: #185d8a;
            padding: 20% 0 0 25%;
            align-items: baseline;
        }
    }

    &__button {
        background-color: #aff2e3;
        color: #185d8a;
        text-align: center;
        font-size: 14px;
        font-family: $font-light;
        letter-spacing: 0.62px;
        padding: 5px 22px;
        border-radius: 50px;
        cursor: pointer;

        @include mobile-tablet {
            font-size: 18px;
            letter-spacing: 0.79px;
        }

        @include tablet-view {
            padding: 5px 50px;
        }
    }

    &__button:hover {
        background: #aff2e3 radial-gradient(circle, transparent 1%, #cdf7ef  1%) center/15000%;
    }

    &__button:active {
        background-color: #aff2e3;
        background-size: 100%;
        transition: background 0s;
    }
}