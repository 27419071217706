@import '../../Styles/global.scss';

.mobile {

    @include mobile-tablet {
      display: none;
    }

    @include tablet-view {
        display: none;
    }
}

.tablet-desktop {
    display: none;

    @include mobile-tablet{
        display: flex;
        justify-content: center;; 
        width: 100%;
        position: absolute;
        padding: 16px 0;

        &__nav {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__bottom {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            
            &__item {
              padding: 40px 25px;
              font-family: $font-light;
              font-size: 18px;
              letter-spacing: 0.79px;
              color: #185d8a;
              cursor: pointer;
            }
        }

        &__img{
          width: 100px;

          @include tablet-view {
            width: 175px;
          }
        }
    }
}



.navlink {
    text-decoration: none;
    color: #28282a;
    cursor: pointer;
}

.nav {
    display: flex;
    justify-content: space-between; 
    width: 53%;
    position: absolute;
    padding: 16px 0 0 22px;

    &__menu-container {
        width: 18px;
        height: 12px;

        &__img {
            width: 100%;
        }
    }

    &__logo-container {
        width: 57px;
        height: 38px;

        &__img {
            width: 100%;
        }

        @include mobile-tablet {
            width: 77px;
        }
    }

    &__menu {

        &__logo {
            border-bottom: #185d8a 1px solid;
            outline: none;
            padding: 20px; 
        }
    }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 18px;
  height: 12px;
  left: 22px;
  top: 19px;
}
 
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #185d8a;
}
 
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}
 
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
 
/* Color/shape of close button cross */
.bm-cross {
  background: #185d8a;
  
}
 
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}
 
/* General sidebar styles */
.bm-menu {
  background: #f8fafd;
  font-size: 1.15em;
}
 
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
 
/* Wrapper for item list */
.bm-item-list {
  color: #28282a;
  text-align: center;
  font-family: $font-light;
  font-size: 14px;
  letter-spacing: 0.62px;

    @include mobile-tablet {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.79px;
    }
}
 
/* Individual item */
.bm-item {
  display: inline-block;
  padding-top: 20px;
}
 
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}