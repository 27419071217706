@import '../../Styles/global.scss';

.contact {
    display: flex;
    flex-direction: column;
    background-color: #28282a;
    color: #f8fafd;
    text-align: center;
    

    &__SVG {
        width: 100%;
        height: 8vh;
    }

    &__title {
        font-family: $font-bold;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.88px;
        margin: 0;
        padding: 10px 0 7px 0;

        @include mobile-tablet {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.32px;
        }
        
    }

    &__description {
        font-family: $font-light;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.53px;
        padding: 0 20px 16px 20px;

        @include mobile-tablet {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.79px;
            margin: 10px 0;
        }
    }

    &__submit-btn {
        background-color: #28282a;
        font-family: $font-light;
        font-size: 18px;
        letter-spacing: 0.79px;
        color: #aff2e3;
        border: #aff2e3 3px solid;
        border-radius: 50px;
        padding: 11px 22px;
        width: 250px;;
        align-self: center;
        margin: 15px 0 30px 0;
        outline: none;
        cursor: pointer;
        background-position: center;
        transition: background 0.8s;
    }

    &__submit-btn:hover {
        color: #28282a;
        cursor: pointer;
        background: #aff2e3 radial-gradient(circle, transparent 1%, #cdf7ef  1%) center/15000%;
    }

    &__submit-btn:active {
        background-color: #aff2e3;
        background-size: 100%;
        transition: background 0s;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px;
        

        &__name {
            height: 32px;
            margin-bottom: 10px; 
            font-family: $font-normal;
            font-size: 14px;
            letter-spacing: 0.62px;
            padding: 0 0 0 10px;
            color: #28282a;
            outline: none;

            @include mobile-tablet {
                width: 446px;
            }
        }

        &__email {
            height: 32px;
            margin-bottom: 10px;
            font-family: $font-normal;
            font-size: 14px;
            letter-spacing: 0.62px;
            padding: 0 0 0 10px; 
            color: #28282a;
            outline: none;

            @include mobile-tablet {
                width: 446px;
            }
        }

        &__message {
            height: 104px;
            margin-bottom: 10px;
            font-family: $font-normal;
            font-size: 14px;
            letter-spacing: 0.62px;
            padding: 8px 0 0 12px; 
            color: #28282a;
            outline: none;
            resize: vertical;

            @include mobile-tablet {
                width: 448px;
            }
        }

        &__nameError {
            border: 2px solid red;
            height: 32px;
            margin-bottom: 10px; 
            font-family: $font-normal;
            font-size: 14px;
            letter-spacing: 0.62px;
            padding: 0 0 0 10px;
            color: #28282a;
            outline: none;

            @include mobile-tablet {
                width: 446px;
            }
        }

        &__emailError {
            border: 2px solid red;
            height: 32px;
            margin-bottom: 10px;
            font-family: $font-normal;
            font-size: 14px;
            letter-spacing: 0.62px;
            padding: 0 0 0 10px; 
            color: #28282a;
            outline: none;

            @include mobile-tablet {
                width: 446px;
            }
        }

        &__messageError {
            border: 2px solid red;
            height: 104px;
            margin-bottom: 10px;
            font-family: $font-normal;
            font-size: 14px;
            letter-spacing: 0.62px;
            padding: 8px 0 0 12px; 
            color: #28282a;
            outline: none;
            resize: vertical;

            @include mobile-tablet {
                width: 448px;
            }
        }
    }

}

.check {
    width: 50px;
    margin-bottom: 20px;
}

.btn {
    width: 100%;
    height: 45px;
    font-size: 18px;
    background-color: #526B95;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
}