@import '../../Styles/global.scss';

.space{
    margin: 15px;
}

.footer {
    background-color: #185d8a;
    height: 104px;

    &__flex {
        display: flex;
        justify-content: center;
    }

    &__container {

        &__linkedin {
            background-image: url(../../Asset/Icon/linkedin-logo.png);
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 32px;
            height: 32px;
        }

        &__github {
            background-image: url(../../Asset/Icon/github-logo.png);
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 32px;
            height: 32px;
        }
    }

    &__copyright {
        text-align: center;
        margin-bottom: 20px;
        font-family: $font-light;
        font-size: 12px;
        letter-spacing: 0.53px;
        color: #f8fafd;
    }
}