@import './font.scss';

$font-light: "OpenSansLight";
$font-normal: "OpenSansNormal";
$font-bold: "OpenSansBold";
$font-semibold: "OpenSansSemiBold";
$font-extrabold: "OpenSansExtraBold";

//screen size - moblie first logic

@mixin mobile {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin mobile-tablet {
    @media (min-width: 500px) {
      @content;
    }
  }

@mixin tablet-view {
    @media (min-width: 768px) {
      @content;
    }
  }

@mixin tablet-desktop {
    @media (min-width: 800px) {
        @content;
    }
} 

@mixin tablet-desktop-1024 {
  @media (min-width: 1024px) {
      @content;
  }
} 

@mixin desktop-view {
    @media (min-width: 1280px) {
        @content;
    }
}