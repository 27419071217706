@import '../../Styles/global.scss';

.icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #f8fafd;
    padding: 0 0 50px 0;
}

.font {
    font-family: $font-light;

    &__title {
        font-family: $font-bold;
        font-size: 20px;
        color: #185d8a;
        text-align: center;
        letter-spacing: 0.88px;
        background-color: #f8fafd;
        padding: 20px 0; 
        margin: 0;

        @include mobile-tablet {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.32px;
        }
    }
}

.about {
    background-color: #28282a;

    &__container {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 0 20px;

        @include tablet-desktop-1024 {
            width: 940px;
            margin: 0 auto;
        }
    }

    &__img {
        display: none;

        &__photo{
            width: 325px;
            position: absolute;
            bottom: 0;
            left: 8%;
        }

        @include tablet-desktop {
            display: flex;
            height: 250px;
        }
    }

    &__text-container {
        text-align: center;

        @include tablet-desktop {
            text-align: initial;
            width: 50%;
        }

        @include tablet-desktop-1024 {
            text-align: initial;
            width: 55%;
        }
    }

    &__title {
        font-family: $font-bold;
        font-size: 16px;
        color: #f8fafd;
        letter-spacing: 0.71px;
     

        @include mobile-tablet {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.32px;
        }
    }

    &__content {
        font-family: $font-light;
        font-size: 12px;
        letter-spacing: 0.53px;
        line-height: 24px;
        color: #f8fafd;
        padding: 0 0 30px 0;

        @include mobile-tablet {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.79px;
        }
    }
}


.skill {
    margin: 15px 0;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    @include mobile-tablet {
        width: 33%;
    }

    @include tablet-desktop {
        width: 10%;
    }

    &__icon {
        background-color: #185d8a;
        background-position: center;
        background-size: 45%;
        background-repeat: no-repeat;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        
        &__text {
            padding:10px 0 0 0;

            @include mobile-tablet {
                font-size: 16px;
                letter-spacing: 0.71px;
            }
        }
    }

    &__html {
        background-image: url(../../Asset/Image/Portfolio/html5-brands.svg);
    }

    &__css {
        background-image: url(../../Asset/Image/Portfolio/css3-alt-brands.svg);
    }

    &__js {
        background-image: url(../../Asset/Image/Portfolio/javascript_seeklogo_com.svg);
    }

    &__typescript {
        background-image: url(../../Asset/Image/Portfolio/typescriptLogo.svg);
    }

    &__react {
        background-image: url(../../Asset/Image/Portfolio/react-brands.svg);
    }

    &__express {
        background-image: url(../../Asset/Image/Portfolio/express_109.svg);
        background-size: 75%;
    }
}