
@font-face {
    font-family: "OpenSansLight";
    src: url(../Asset/Font/OpenSansLight.otf)
}

@font-face {
  font-family: "OpenSansNormal";
  src: url(../Asset/Font/OpenSansRegular.otf);
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url(../Asset/Font/OpenSansSemiBold.otf);
}

@font-face {
    font-family: "OpenSansBold";
    src: url(../Asset/Font/OpenSansBold.otf);
}

@font-face {
    font-family: "OpenSansExtraBold";
    src: url(../Asset/Font/OpenSansExtraBold.otf);
}